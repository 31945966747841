<template>
  <v-app-bar id="app-bar" absolute app color="#bdc3ce" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-dots-vertical </v-icon>
    </v-btn>

    <v-toolbar-title
      style="width: 300px; "
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <v-col> </v-col>
    <v-col style="min-width: 500px; padding-top: 20px; text-align: right;">
      <v-card-title v-if="this.$store.state.enteSel != null">
        <span class="text-h4; justify-left">{{
          this.$store.state.enteSel.cDescrizione
        }}</span>
      </v-card-title>
    </v-col>
    <v-col> </v-col>
<!--
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge color="red" overlap bordered>
            <template v-slot:badge>
              <span>2</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>
-->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div v-for="(n, i) in profileMenu" :key="`item-${i}`">
          <div @click="onItemClick(n)">
            <app-bar-item >
              <v-list-item-title v-text="n"  />
            </app-bar-item>
          </div>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations } from "vuex";

import AuthService from "@/service/AuthService";
import EntiService from "@/service/EntiService";

import Utils from "@/utils/Utils";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "black--text selected elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    notifications: ["Messaggio 1", "Messaggio 2"],
    profileMenu: ["Profilo", "Logout"],
    shouldDisable: false,
  }),

  created() {
  },

  computed: {
    user: {
      get() {
        return this.$store.state.user;
      },
      set(val) {
        this.$store.commit("SET_USER", val);
      },
    },
    ...mapState(["drawer"]),
  },

  methods: {
    onItemClick(objSel) {
      if (objSel == "Profilo") {
        this.$router.push({
          name: "User Profile",
          params: { showDialog: true },
        });
      } else if (objSel == "Logout") {
        this.onLogoutClick();
      }
    },
    onLogoutClick() {
      this.$store.commit("SET_ENTE_SELECTED", null);
      this.$store.commit("SET_USER", null);
      this.$store.commit("SET_ROLES", null);
      this.$store.commit("SET_ROLE_SELECTED", null);
      this.$store.commit("SET_PERMISSIONS", null);

      AuthService.logout();
    },

    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
  },
};
</script>
